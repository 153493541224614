<template>
    <div class="industry_trends_child_div">
        <div class="nav_div"></div>
        <div class="industry_trends_content_div allContentWidth">
            <div class="return_box">
                <span @click="backTopPage"><< 返回新闻活动列表</span>
            </div>
            <div class="industry_trends_content_box">
                <p class="title_p">{{ contentObj.title }}</p>
                <div class="time_from_div">
                    <span class="time_span">{{ contentObj.time }}</span>
                    <span v-if="contentObj.from" class="from_span">来自：{{ contentObj.from }}</span>
                </div>
                <div class="paragraph_div">
                    <p v-for="item in contentObj.content" :key="item.id">{{ item.text }}</p>
                </div>
                <div class="news_img_div">
                    <div v-for="a in contentObj.img_url">
                        <img v-if="a.height" :style="'height:'+a.height" :src="a.url"/>
                        <img v-if="!a.height" :src="a.url"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
        this.initData();
    },
    watch: {},
    methods: {
        backTopPage() {
            this.$router.push(`/news`);
        },
        initData() {
            this.contentAll.map((item) => {
                if (item.key === Number(this.$route.params.id)) {
                    this.contentObj = item;
                }
            })
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        return {
            imgSrc: imgSrc,
            contentObj: {},
            contentAll: [
                {
                    key: 1,
                    title: '24000TEU集装箱巨轮在长兴岛交付',
                    time: '2022-7-12 11:07:02',
                    from: '中新网',
                    content: [
                        {
                            id: 1,
                            text: '7月11日，由中船集团江南造船建造的24000TEU(国际标准箱)超大型集装箱船、巴拿马籍“长颖(EVERAPEX)”轮在江南造船长兴基地顺利交付。'
                        },
                        {
                            id: 2,
                            text: '崇明边检站快速办妥新船交付边检手续，保障其顺利开航。“长颖”轮集装箱船是长荣集团在中国船舶集团订造的6艘24000TEU级集装箱项目船的一艘。'
                        },
                        {
                            id: 3,
                            text: '作为目前全球最大集装箱量的集装箱船，该系列船舶由我国自主设计，拥有完全的自主知识产权，总长399.99米，型宽61.5米，型深33.2米，可承载24万吨货物，一次可装卸24000个标准集装箱，最大堆箱层数可达25层。'
                        },
                        {
                            id: 4,
                            text: '据江南造船相关负责人介绍，上半年受到疫情影响，长兴岛各修造船企业新船建造、船舶试航等工程进度一度中断。随着企业全面复工复产，在边检机关等口岸单位的支持下，团队员工全力追赶因疫情耽误的工期。一个月内两艘24000TEU超大型集装箱新船的接连交付。'
                        },
                        {
                            id: 5,
                            text: '在接到新船交付申请后，崇明边检站提前抽调骨干成立工作小组，掌握船员信息，在首制船交付手续的基础上，进一步完善查验手续，提升工作效率。'
                        },
                        {
                            id: 6,
                            text: '11日下午，工作组顶着高温酷暑在码头现场为首航船员办理边检手续，对船舶重点部位实施船体检查。通过高效的查验流程，边检部门协助企业快速完成新船交付手续，确保该轮按期启航。'
                        },
                        {
                            id: 7,
                            text: '为保障企业在特殊时期最大限度恢复生产经营，崇明边检站积极响应企业和船方诉求，针对不同类型船舶修造交付任务，制定精细化举措，靠前服务，为船舶出入境手续办理，登离轮人员审核等方面提供保障，尽最大可能提升口岸通过效率和码头泊位使用效率。'
                        },
                        {
                            id: 8,
                            text: '今年疫情防控以来，该站已顺利保障完成24000TEU集装箱船“长益”轮、“长颖”轮、双燃料23000TEU集装箱船“达飞蒙马特”轮、双燃料15000TEU集装箱船“达飞加拉帕戈斯”轮、江海联运型LNG船“传奇太阳”轮等一系列新造船交付手续，助力中国造船业蓬勃发展。'
                        }
                    ]
                },
                {
                    key: 2,
                    title: '德国汉堡港码头工人今起开始大罢工，所有港口作业全部暂停',
                    time: '2022-7-14 14:18:56',
                    from: '壹航运',
                    content: [
                        {
                            id: 1,
                            text: '由于与雇主的工资谈判陷入僵局，德国码头工人将于当地时间7月14日6:00(即北京时间今天中午12点)开始进行长达48小时的罢工活动。'
                        },
                        {
                            id: 2,
                            text: '据一家铁路运输公司(Rail Transportation Service Broker GmbH; RTSB)的官方通知显示：他们收到了关于汉堡港从 2022 年 7 月 14 日 06:00 开始进行 48 小时警告罢工的通知，汉堡的所有码头都参加了这次警告罢工(CTA、CTB、CTT、EUROGATE/EUROKOMBI、BILLWERDER DUSS、STEINWEG SÜD-WEST 等)铁路和卡车运输的所有运营都将暂时停止——在这段时间内提取和交付货物将是不可能的。'
                        },
                        {id: 3, text: '以下是通知详细内容：'},
                        {id: 4, text: 'Dear valued customers and partners,'},
                        {
                            id: 5,
                            text: 'today we received notification about 48-hour warning strike in port of Hamburg starting from 14.07.2022 06:00h until 16.07.2022 06:00h. All terminals in Hamburg are attending in this warning strikes (CTA、CTB、CTT、EUROGATE/EUROKOMBI, BILLWERDER DUSS, STEINWEG SÜD-WEST, etc.)'
                        },
                        {
                            id: 6,
                            text: 'All operations of rail and trucking will be temporary stopped – in this period of time pick up and delivery of containers will be impossble'
                        },
                        {id: 7, text: 'If you have any urgent shipments – please arrange trucks for today'},
                        {
                            id: 8,
                            text: 'Please notify your suppliers and customers accordingly to avoid any waiting times and/or additional costs.'
                        },
                        {id: 9, text: 'If any questions please be welcome to contact our operational department'},
                        {id: 10, text: 'We will keep you informed in case of any new developments'},
                        {
                            id: 11,
                            text: '12,000名港口工人的罢工将使汉堡、不来梅港和威廉港等主要集装箱枢纽港的运营陷入瘫痪，这将是日益激烈的劳资纠纷中第三次--最长的一次罢工，也是德国40多年来最长的港口罢工。'
                        },
                        {
                            id: 12,
                            text: '如果罢工继续进行，将对班轮网络产生严重影响，并加剧北欧集装箱枢纽本已长期存在的供应链拥塞。'
                        },
                        {id: 13, text: '谈判再次以失败结束'},
                        {
                            id: 14,
                            text: '据悉，上周，德国港口运营商协会 (ZDS) 和德国最大的服务行业工会Verdi 之间的第六轮谈判宣告失败，谈判的破裂使罢工危机加剧。'
                        },
                        {
                            id: 15,
                            text: '“雇主没有充分满足我们对实际通胀补偿的要求，”Verdi 的首席谈判代表 Maya Schwiegershausen-Güth 说。'
                        },
                        {
                            id: 16,
                            text: '“我们需要一个真正的通胀补偿，这样所有公司的员工就不会因为价格飞速上涨而独自承担后果，”Schwiegershausen-Güth表示。'
                        },
                        {id: 17, text: '此外，她还称 ZDS 早些时候的报价是“典型的骗局”( a classic sham )。'},
                        {
                            id: 18,
                            text: '然而，ZDS 谈判代表 Ulrike Riedel 在评论谈判僵局时抱怨说，尽管“报价不断提高”，但工会“没有妥协的意愿”，并声称它已经“回应了 Verdi 的要求”。'
                        },
                        {
                            id: 19,
                            text: 'Riedel 说，有一个“最高 12.5% 的报价”，其中包括追溯至 6 月 1 日的 8% 的永久性增长。'
                        },
                        {
                            id: 20,
                            text: '并表示，码头工人罢工行动的任何进一步升级都将“与这一提议完全不相称，不仅损害我们的利益，而且损害整个德国”，“我们迫切需要一个调解程序。”'
                        },
                        {id: 21, text: '罢工将导致港口拥堵进一步恶化'},
                        {
                            id: 22,
                            text: '在此次罢工之前，汉堡码头的堆场密度就一直在恶化，据称，由于工资谈判的负面影响，导致码头额外航次数量的减少。'
                        },
                        {
                            id: 23,
                            text: '此外，由于进口列车出现延误，导致货柜运抵严重延误，以致未能接货，METRANS于周六宣布暂停出口列车的运营。'
                        },
                        {
                            id: 24,
                            text: '与此同时，海运承运人正在观望是否会在最后一刻得到喘息机会或干预措施，以防止北欧船舶运营进一步混乱。'
                        },
                        {
                            id: 25,
                            text: '与此同时，海运承运人正在观望是否会在最后一刻得到喘息机会或干预措施，以防止北欧船舶运营进一步混乱。'
                        }
                    ]
                },
                {
                    key: 3,
                    title: '2022年全球Top20集装箱港口排名预测：中国占9席',
                    time: '2022-7-13 11:08:14',
                    from: '中国财富网',
                    content: [
                        {
                            id: 1,
                            text: '7月12日，中国科学院预测科学研究中心发布《2022年全球Top20集装箱港口预测报告》。报告显示，在全球前20大集装箱港口中，中国占了9个，且其中7个跻身前10。'
                        },
                        {
                            id: 2,
                            text: '　与2021年相比，今年前10名港口排名顺序未发生变动，分别为上海港、新加坡港、宁波舟山港、深圳港、广州港、青岛港、釜山港、天津港、香港港、鹿特丹港。2022年全球Top20港口集装箱吞吐量预测排名。'
                        },
                        {
                            id: 3,
                            text: '报告显示，2022年，受新冠疫情、发达经济体逆全球化举措和俄乌冲突等影响，全球经济面临较大的下行压力，集装箱运输业出现放缓态势。'
                        },
                        {
                            id: 4,
                            text: '根据模型测算结果，全球Top20集装箱港口有9个来自中国，中国集装箱运输的发展仍然是全球稳定发展的重心和基础。2022年中国多数港口集装箱运输服务需求呈现增长，其中尤以宁波舟山、青岛和天津港上升明显。'
                        },
                        {
                            id: 5,
                            text: '从整体来看，报告预计2022年全球前20大集装箱港口与2021年变化不大，港口排名发生变化的情况包括：巴生港将超过杰贝阿里港，位列全球第11位，杰贝阿里港退居第12位;长滩港、纽约-新泽西港排名上升一位，分别位列第17、18名，高雄港下降两位至第19位。'
                        },
                        {
                            id: 6,
                            text: '总体来看，今年全球Top20集装箱港口集中在东亚、欧洲和北美洲地区，其中中国港口表现良好，不仅港口数量多，且吞吐量增幅均保持良好发展态势。按区域分析，美国港口从高速转为稳健增长，中国、东南亚和欧洲地区港口均呈现差异化发展趋势。'
                        },
                    ]
                },
                {
                    key: 4,
                    title: '2022年上半年中国造船国际市场份额保持全球领先',
                    time: '2022-7-15 11:19:3',
                    from: '中新网',
                    content: [
                        {
                            id: 1,
                            text: '中国工业和信息化部14日发布的数据显示，2022年1-6月，中国造船完工量、新接订单量和手持订单量国际市场份额均位居世界第一。'
                        },
                        {
                            id: 2,
                            text: '2022年1-6月，全国造船完工量1850万载重吨，同比下降11.6%，其中海船为593万修正总吨;新接订单量2246万载重吨，同比下降41.3%，其中海船为1113万修正总吨。截至6月底，手持订单量10274万载重吨，同比增长18.6%，其中海船为4229万修正总吨，出口船舶占总量的88.7%。'
                        },
                        {
                            id: 3,
                            text: '1-6月，中国造船完工量、新接订单量和手持订单量以载重吨计分别占世界总量的45.2%、50.8%和47.8%，按修正总吨计分别占42.0%、47.7%和41.5%，国际市场份额均位居世界第一。'
                        },
                        {
                            id: 4,
                            text: '1-6月，全国造船完工量前10家企业集中度为70.9%，比2021年底提高1.6个百分点;新承接订单量前10家企业集中度为68.3%，比2021年底下降1.0个百分点;手持订单量前10家企业集中度为66.5%，比2021年底下降3.0个百分点。'
                        },
                        {
                            id: 5,
                            text: '中国船舶工业行业协会此前的统计数据显示，1-5月，完工船舶产品中，工程船占比提高较快，新接船舶产品中，气体船同比增长最多。'
                        },
                    ]
                },
                {
                    key: 5,
                    title: '美国卡车司机罢工，铁路工人将罢工，长滩码头闸口临时关闭！',
                    time: '2022-7-14 14:06:42',
                    from: '外航运',
                    content: [
                        {id: 1, text: '受加州AB5法案影响，美西三大港口--洛杉矶、长滩和奥克兰的卡车司机相继举行罢工!'},
                        {
                            id: 2,
                            text: '雪上加霜的是，美国全国货运铁路劳工谈判也面临最后期限，如果政府拒绝介入，下周一铁路和工会将选择停业或罢工。'
                        },
                        {
                            id: 3,
                            text: '这将加剧洛杉矶长滩港集装箱积压，也将进一步加剧美国港口拥堵，对美国疲软的经济造成"灾难性的"影响。'
                        },
                        {id: 4, text: '01美西三大港口卡车司机开始罢工'},
                        {id: 5, text: '洛杉矶/长滩港于美国时间7月13日起开始罢工，奥克兰港将于美国时间18日起开始罢工。'},
                        {
                            id: 6,
                            text: '据美国时间周三下午4时最新消息，洛杉矶和长滩港口的大约100名卡车司机已经停止工作，以抗议加州AB5法案这一有争议的立法。'
                        },
                        {
                            id: 7,
                            text: '6月底，美国最高法院拒绝审理加州卡车运输协会对AB5的诉讼，将该案发回美国第九巡回上诉法院。'
                        },
                        {
                            id: 8,
                            text: '一位计划参加港口罢工活动的业主和运营商表示，他不想成为一名雇员司机，他更愿意继续做一个独立的承包商。'
                        },
                        {
                            id: 9,
                            text: 'I总部位于南加州的FHE Express公司的经理Gordon Reimer表示，该公司使用75名业主和经营者在南加州的港口之间运输货物，其中许多人计划参加周三的罢工。'
                        },
                        {id: 10, text: '他已经通知货运公司的客户，罢工可能会导致货运延误。'},
                        {id: 11, text: '目前，尚不清楚有多少独立承包商或业主经营人计划参加此次的罢工活动。'},
                        {id: 12, text: '此外，在罢工期间，长滩集港装箱码头部分闸口将暂时关闭!'},
                        {id: 13, text: '日前，长滩集装箱码头(LBCT)通过官网发布最新公告：'},
                        {id: 14, text: '7月14日(周四)以及7月17日(周日)，码头闸口部分时段将被关闭!'},
                        {id: 15, text: '预计集装箱码头闸口的关闭，将对提柜和还柜作业带来影响!'},
                        {id: 16, text: '02奥克兰港将于美国时间18日罢工'},
                        {
                            id: 17,
                            text: '与此同时在北方，多家运输集装箱进出奥克兰港的卡车公司透露已经接到业主经营者的通知，他们计划在下周一(7月18日)罢工。'
                        },
                        {
                            id: 18,
                            text: 'AB Trucking总裁Bill Aboudi表示，其在几年前转向了雇佣的经营模式，但随着AB5的影响逐渐显现，许多服务于加州港口的卡车公司都选择关门、出售或将业务迁出加州。'
                        },
                        {
                            id: 19,
                            text: '一些年长的业主经营者被逼无奈正在离开这个行业。一些年轻的独立经营者，要么另谋职业，要么完全离开加州去其他地方开卡车。'
                        },
                        {
                            id: 20,
                            text: '“我们认为目前通货膨胀很高;如果我们不把AB5取消，就等着运营卡车业务的成本飙升吧。”Bill Aboudi说道。'
                        },
                        {id: 21, text: '03美国铁路工人也将大罢工'},
                        {
                            id: 22,
                            text: '除了罢工，美国总统拜登下周还将面临干预美国全国铁路劳工谈判的最后期限，该谈判涉及115,000名铁路工人，否则将引发罢工或停工。'
                        },
                        {id: 23, text: '由此可能会威胁本已脆弱的经济，并导致食品和燃料供应中断。'},
                        {
                            id: 24,
                            text: '这对拜登来说事关重大，他希望解决引发通胀的供应链问题，并已在西海岸海港的关键劳工谈判中努力达成协议，虽然美西三大港口宣布罢工。'
                        },
                        {
                            id: 25,
                            text: '如果总统拒绝在美国东部时间下周一12:01之前任命总统紧急事务委员会(PEB)介入铁路劳工谈判，铁路和工会可以分别选择停业或罢工。'
                        },
                        {
                            id: 26,
                            text: '代表零售商、食品和燃料生产商的美国商业团体在致拜登的信中警告说，如果不任命PEB，将对疲软的经济造成"灾难性的"影响。'
                        },
                        {
                            id: 27,
                            text: '包括从亚马逊的包裹到燃料油和大豆的一切物品的铁路运输，任何形式的关闭都可能推高必需品的价格，颠覆受损的供应链。'
                        },
                        {
                            id: 28,
                            text: '美国货运铁路公司向投资者保证，他们正在与工会就薪资、休假和医疗成本分担问题进行谈判，并在招聘和留用方面取得进展。'
                        },
                        {
                            id: 29,
                            text: '“解决这一争端，为所有铁路员工及时加薪，并防止铁路服务中断，仍然符合各方和公众的最佳利益。”美国全国铁路劳工大会(NRLC)在最新的谈判中表示。'
                        }
                    ]
                },
                {
                    key: 6,
                    title: '国际航行船舶总吨位全国第二 洋浦港口上半年交出亮眼答卷',
                    time: '2022-7-11 8:50:11',
                    from: '南海网',
                    content: [
                        {
                            id: 1,
                            text: '近日，记者从洋浦交通运输和港航局了解到，今年上半年，洋浦国际集装箱码头吞吐量表现亮眼，带动洋浦港口各类业务量持续上涨。'
                        },
                        {
                            id: 2,
                            text: '据介绍，2022年上半年，洋浦港口货物吞吐量预计完成2461万吨，集装箱吞吐量完成82.3万标箱，同比增长45.69%，预计完成水运周转量5024.28亿吨公里，同比增长29.44%;上半年船舶固投实际已纳统19.16亿元，其中一季度4.19亿，二季度14.97亿。2022年上半年，洋浦新增航运产业类相关市场主体79家，新增海事登记船舶54艘，载重吨201.93万。'
                        },
                        {
                            id: 3,
                            text: '记者还了解到，今年5月，海南嘉骅物流有限公司新建造散货船“嘉航达”轮通过远程视频办理注册至“洋浦”船籍港，使得洋浦登记海船总运力历史性突破1000万载重吨。'
                        },
                        {
                            id: 4,
                            text: '同时，上半年新增登记“中国洋浦港”的船舶中有第一艘“零关税”进口的油化船和全球首艘LNG双燃料动力超大型油轮，大量国际船舶的登记注册使海南省国际航行船舶总吨位跻身全国第二。'
                        },
                        {
                            id: 5,
                            text: '目前，为了满足日益增长的海运需求，洋浦区域国际集装箱枢纽港扩建工程和洋浦港区深水航道改扩建工程正在加紧推进，目前保障两项目的区域港口规划修订已于5月31日获得生态环境部批复同意规划环评，力争于9月底正式动工。'
                        },
                        {
                            id: 6,
                            text: '另外，保障国投油储2个3万吨级油品化工品码头工程、海南逸盛洋浦莲花山临港石化物流园码头改扩建工程、国投洋浦港10万吨级公共粮油码头工程等也已启动规划。'
                        },
                    ]
                },
                {
                    key: 7,
                    title: '上海港去年完成4730万吞吐量，连续13年成为世界最繁忙港口',
                    time: '2023.01',
                    content: [
                        {
                            id: 101,
                            text: '2022年，上海港仍然是世界上最繁忙的集装箱港口，但由于中国主要港口发生了与Covid-19相关的中断事件，同比仅略有增长。'
                        },
                        {
                            id: 102,
                            text: '官方媒体援引港口运营商上海国际港务（集团）有限公司的数据报道，该港去年的集装箱吞吐量超过4730万个20英尺当量单位（TEU）。'
                        },
                        {
                            id: 103,
                            text: '新华社报道：“尽管新冠肺炎疫情肆虐，但上海港在2022年连续第13年成为世界上最繁忙的集装箱港口。”。'
                        },
                        {
                            id: 104,
                            text: '该公司补充称，继第二季度疫情之后，该港口在7月份处理了创纪录的430万标箱的月度吞吐量，从而实现了集装箱吞吐量的“快速V型复苏”。'
                        },
                        {id: 105, text: '尽管如此，该港口2022年的表现仅略高于2021上海港记录的4700万标准箱。'},
                        {
                            id: 106,
                            text: '截至11月，该港最接近的竞争对手是新加坡港，该港在2022年1月至11月期间的吞吐量为3400万标准箱。'
                        },
                    ],
                    img_url: [{url: require('../../assets/img/home_hnews7_1.png'), height: '415px;'},
                        {url: require('../../assets/img/home_hnews7_2.png')}]
                },
                {
                    key: 8,
                    title: '好消息！中国铁路货运量去年完成39亿吨贡献4786亿元收入',
                    time: '2023.01',
                    content: [
                        {
                            id: 101,
                            text: '中国官方媒体援引中国国家铁路集团有限公司的数据称，去年铁路货运服务收入达到4786亿元人民币（约692.3亿美元），比2021增长9.8%。'
                        },
                        {id: 102, text: '去年，中国在2022年投入运营的新铁路线路约4100公里，其中超过一半是高速铁路。'},
                        {
                            id: 103,
                            text: '该报告补充道：“今年，全国铁路运营商预计客运量将比2022年增长67%以上，预计总体运输收入将普遍回升至疫情前的水平。”。'
                        },
                        {id: 104, text: '与此同时，中国西北部陕西省省会西安在2022年见证了中欧货运列车旅行的激增。'},
                        {id: 105, text: '2023年，超过3000公里的新铁路线将投入使用，其中2500公里为高速铁路。'},
                        {
                            id: 106,
                            text: '新华社表示，长安中欧货运列车去年开行4639趟列车，同比增长20.8%，运输货物近412万吨，年增长43.3%。'
                        },
                        {
                            id: 107,
                            text: '2022年，该市在中国运营中欧货运服务的城市中，火车旅行次数、货运量和重型集装箱率均位居首位。'
                        },
                        {
                            id: 108,
                            text: '自2013年开通以来，长安铁路已开通了17条连接西安与亚洲和欧洲目的地的货运线路，共处理了16054次列车旅行。'
                        },
                    ],
                    img_url: [{url: require('../../assets/img/home_hnews8_1.png'), height: '500px;'},
                        {url: require('../../assets/img/home_hnews8_2.png'), height: '250px;'}]
                },
                {
                    key: 9,
                    title: '重磅交易！赫伯罗特航运和贝恩资本科塔克家族达成协议，收购印度JMBPL港口物流40%股权',
                    time: '2023.05',
                    content: [
                        {
                            id: 101,
                            text: '赫伯罗特宣布，其成功完成了对印度J M Baxi港口物流有限公司（JMBPL）40%股权的收购，该公司此前已获得相关反垄断机构的批准。'
                        },
                        {
                            id: 102,
                            text: '这家航运巨头在一份声明中表示，2023年1月，它已与贝恩资本私人股本公司的一家附属公司和科塔克家族达成了一项协议，贝恩资本私募股权公司持有JMBPL大约35%的股份，科塔克家族拥有JMBPL的大部分股份。'
                        },
                        {
                            id: 103,
                            text: '在推动其2023年战略的过程中，赫伯罗特指出，通过收购总部位于智利的SM SAAM终端业务的协议，其在终端行业的参与不断扩大。'
                        },
                        {
                            id: 104,
                            text: '此外，赫伯罗特还持有意大利Spinelli集团、威廉港集装箱码头、汉堡Altenwerder集装箱码头、丹吉尔TC3码头和埃及达米埃塔2号码头的股份，这些码头目前正在建设中。'
                        },
                        {
                            id: 105,
                            text: '“通过收购JMBPL的股份，赫伯罗特正在加强其在印度有吸引力的增长市场的影响力，同时扩大其码头和基础设施业务。”'
                        },
                        {id: 106, text: '赫伯罗特指出，尽管完成了收购，但签约方同意不披露交易的任何财务细节。'},
                        {id: 107, text: '赫伯罗特早些时候表示，此次收购将加强这家航运巨头在印度关键战略增长。'},
                    ],
                    img_url: [{url: require('../../assets/img/home_hnews9_1.png')}]
                },
                {
                    key: 10,
                    title: '旭日东升，国旗飘扬，中华人民共和国已经成立74年。',
                    time: '2023.09',
                    content: [
                        {
                            id: 108,
                            text: '初心始终，砥砺前行，唱响了一首首中华赞歌。欢度国庆，爱我中华，愿祖国繁荣昌盛，欣欣向荣。'
                        }
                    ],
                    img_url: [{url: require('../../assets/img/2023-gq1.jpg')}, {url: require('../../assets/img/2023-gq2.jpg')}]
                },
                {
                    key: 11,
                    title: '新年快乐',
                    time: '2024.02',
                    content: [],
                    img_url: [{url: require('../../assets/img/xnkl.jpg')}]
                }
            ],
        }
    }
}
</script>
<style lang="less">
.industry_trends_child_div {
  .industry_trends_content_div {
    .return_box {
      margin: 50px 0 50px 0;
      cursor: pointer;

      span {
        font-size: 14px;
      }
    }

    .industry_trends_content_box {
      line-height: 50px;
      padding: 0 0 80px 0;

      .title_p {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }

      .time_from_div {
        text-align: center;

        .time_span {
          font-size: 16px;
          //margin: 0 100px 0 0;
          // display: inline-block;
        }

        .from_span {
          margin-left: 50px;
          font-size: 16px;
        }
      }

      .paragraph_div {
        width: 80%;
        margin: 0 auto;

        p {
          text-indent: 24px
        }
      }

      .news_img_div {
        width: 100%;
        margin: 30px auto 0px;

        div {
          margin-bottom: 10px;
          text-align: center;

          img {
            //height: 350px;
            width: 50%;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .industry_trends_child_div {
    .industry_trends_content_div {
      padding: 0 15px;

      .return_box {
        padding-left: 0px;
      }

      .industry_trends_content_box {
        .news_img_div {
          width: 100%;
          height: auto;

          div {
            img {
              height: auto !important;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
